import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { theme } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import Field from "src/components/form/Field";
import SingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import { logTutorEvent } from "src/utils/log";
import { tutorSubjectsByGrade } from "src/utils/tutorGradeAndSubject";
import SubjectSelect from "src/components/SubjectSelect";
import { TutorSubject } from "src/utils/constants";

const FormSchema = z.object({
  subject: z.string(),
});
type FormData = z.infer<typeof FormSchema>;

const AdditionalSingleSessionStepTwo = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
  });
  const grade = state?.booking?.grade;

  useOnFirstRender(() => {
    if (state.booking) {
      const { subject } = state.booking;
      if (subject) form.setValue("subject", subject, { shouldValidate: true });
    }
  });

  useOnFirstRender(() => {
    if (state.booking?.grade && state.booking?.first_name_child) {
      logTutorEvent({
        eventName: "parent.marketplace.logged_in_trial_booking.step_2",
        metadata: {
          grade: state.booking.grade,
          childName: state.booking.first_name_child,
        },
        experiments: ["web_marketplace_post_trial_book_another_trial_dec2024"],
      });
      logTutorEvent("parent.marketplace.retrial_subject_viewed");
    } else {
      push("/additional-single-class/step-one");
    }
  });

  const onNextStep: SubmitHandler<FormData> = async (values) => {
    logTutorEvent("parent.marketplace.retrial_subject_completed");
    dispatch({
      type: actionTypes.UPDATE_BOOKING,
      payload: {
        ...state?.booking,
        subject: values.subject,
      },
    });
    if (state.booking?.isNewChild) {
      push("/additional-single-class/step-three");
    } else {
      push("/additional-single-class/step-six");
    }
  };

  const subjects: TutorSubject[] = ["math", "reading"];
  const filteredSubjects = grade
    ? tutorSubjectsByGrade(grade).filter(({ subject }) => {
        return subjects.some((gradeSubject) => gradeSubject === subject);
      })
    : [];

  return (
    <SingleClassForm
      disabled={!form.formState.isValid}
      title={`Which subject do you want tutoring in for ${state?.booking?.first_name_child ?? "your child"}?`}
      subtitle="Select one"
      onBack={() => push("/additional-single-class/step-one")}
      onSubmit={form.handleSubmit(onNextStep)}
      contentMaxWidth={430}
    >
      <div sx={sxFieldsWrapper}>
        <Field
          form={form}
          name="subject"
          render={({ value, onChange }) => (
            <div sx={sxField}>
              <SubjectSelect subjects={filteredSubjects} onChange={onChange} value={value} />
            </div>
          )}
        />
      </div>
    </SingleClassForm>
  );
};

export default AdditionalSingleSessionStepTwo;

const sxFieldsWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  marginTop: "dt_xl",
  marginBottom: "dt_xl",
  width: "100%",
  gap: "dt_xl",

  [`@media (max-width: ${theme.breakpoints.s})`]: {
    marginTop: "0",
    gap: "dt_l",
  },
};

const sxField: ThemeUIStyleObject = { display: "flex", flexDirection: "column", gap: "dt_xs" };
