import CommunityGuidelines from "old/src/pageComponents/CommunityGuidelines";
import ContentPolicy from "old/src/pageComponents/ContentPolicy";
import CookiesPolicy from "old/src/pageComponents/CookiesPolicy";
import CreatePassword from "old/src/pageComponents/CreatePassword";
import Meeting from "old/src/pageComponents/Meeting";
import Invoices from "old/src/pageComponents/Parents/Invoices";
import Reschedule from "old/src/pageComponents/Parents/Reschedule";
import ChildPrivacyTerms from "old/src/pageComponents/Privacy/ChildPrivacyTerms";
import PrivacyPolicyJanuary2023 from "old/src/pageComponents/Privacy/PrivacyPolicyJanuary2023";
import Availability from "old/src/pageComponents/Teachers/Availability";
import ThirdPartyServiceProviders from "old/src/pageComponents/ThirdPartyServiceProviders";
import Transparency from "old/src/pageComponents/Transparency";
import TutorMeeting from "old/src/pageComponents/TutorMeeting";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import RequireLoggedIn from "./components/RequireLoggedIn";
import AITransparencyNote from "./pageComponents/AITransparencyNote";
import AdditionalTrialSessionBooking from "./pageComponents/AdditionalSingleSession/Booking";
import AdditionalTrialSessionStepFive from "./pageComponents/AdditionalSingleSession/StepFive";
import AdditionalTrialSessionStepFour from "./pageComponents/AdditionalSingleSession/StepFour";
import AdditionalTrialSessionStepSix from "./pageComponents/AdditionalSingleSession/StepSix";
import AdditionalTrialSessionStepThree from "./pageComponents/AdditionalSingleSession/StepThree";
import AdditionalTrialSessionStepTwo from "./pageComponents/AdditionalSingleSession/StepTwo";
import AdditionalSingleSessionSuccess from "./pageComponents/AdditionalSingleSession/Success";
import TutorCreateAssessment from "./pageComponents/Assessment/Create";
import GeneratedAssessmentReview from "./pageComponents/Assessment/GeneratedAssessmentReview";
import ParentAssessment from "./pageComponents/Assessment/ParentView";
import { sxWrapperBackground } from "./pageComponents/NewSingleClass/SharedStyles";
import NotFound from "./pageComponents/NotFound";
import ConsentConfirmation from "./pageComponents/Parent/ConsentConfirmation";
import PostSessionSurvey from "./pageComponents/PostSessionSurvey";
import DirectBookingChildInformation from "./pageComponents/Subscription/DirectBooking/ChildInformation";
import DirectBookingParentSignup from "./pageComponents/Subscription/DirectBooking/ParentSignup";
import DirectBookingSubjectSelection from "./pageComponents/Subscription/DirectBooking/SubjectSelection";
import DirectBookingTutorIntroduction from "./pageComponents/Subscription/DirectBooking/TutorIntroduction";
import SubscriptionBookingRedirectFromClass from "./pageComponents/SubscriptionBookingRedirectFromClass";
import TutorApplicationRedirect from "./pageComponents/TutorApplicationRedirect";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/communityguidelines"
        element={
          <Layout layout="padded">
            <CommunityGuidelines />
          </Layout>
        }
      />
      <Route
        path="/contentpolicy"
        element={
          <Layout layout="padded">
            <ContentPolicy />
          </Layout>
        }
      />
      <Route
        path="/cookies-policy"
        element={
          <Layout layout="padded">
            <CookiesPolicy />
          </Layout>
        }
      />
      <Route
        path="/create-password/:token/:email"
        element={
          <Layout layout="centered">
            <CreatePassword />
          </Layout>
        }
      />
      <Route
        path="/meeting/:token"
        element={
          <Layout layout="centered">
            <Meeting />
          </Layout>
        }
      />
      <Route
        path="/tutor/meeting/:token"
        element={
          <Layout layout="centered">
            <TutorMeeting />
          </Layout>
        }
      />
      <Route
        path="/parents/classes/assessment/:assessmentId"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <ParentAssessment />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/parents/invoices"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <Invoices />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/parents/reschedule/:enrollmentId"
        element={
          <RequireLoggedIn>
            <Layout layout="padded" footer={false}>
              <Reschedule />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/parents/review"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <PostSessionSurvey />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/parents/share-assessment/:stripeSubscription"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <ConsentConfirmation />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/child-privacy-terms"
        element={
          <Layout layout="padded">
            <ChildPrivacyTerms />
          </Layout>
        }
      />

      <Route
        path="/privacy-jan-2023"
        element={
          <Layout layout="padded">
            <PrivacyPolicyJanuary2023 />
          </Layout>
        }
      />
      <Route
        path="/teachers/availability"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <Availability />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/teachers/calendar/assessment/:assessmentId"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <TutorCreateAssessment />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/teachers/generatedAssessment/:generatedAssessmentId/review"
        element={
          <RequireLoggedIn>
            <Layout layout="padded">
              <GeneratedAssessmentReview />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/third-party-service-providers"
        element={
          <Layout layout="padded">
            <ThirdPartyServiceProviders />
          </Layout>
        }
      />
      <Route
        path="/transparency"
        element={
          <Layout layout="padded">
            <Transparency />
          </Layout>
        }
      />
      <Route
        path="/tutor/apply"
        element={
          <Layout layout="centered">
            <TutorApplicationRedirect />
          </Layout>
        }
      />
      <Route
        path="/subscription/booking/class/:classId/redirect-from-class"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <SubscriptionBookingRedirectFromClass />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/step-two"
        element={
          <RequireLoggedIn>
            <Layout layout="default" footer={false} sx={sxWrapperBackground}>
              <AdditionalTrialSessionStepTwo />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/step-three"
        element={
          <RequireLoggedIn>
            <Layout layout="default" footer={false} sx={sxWrapperBackground}>
              <AdditionalTrialSessionStepThree />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/step-four"
        element={
          <RequireLoggedIn>
            <Layout layout="default" footer={false} sx={sxWrapperBackground}>
              <AdditionalTrialSessionStepFour />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/step-five"
        element={
          <RequireLoggedIn>
            <Layout layout="default" footer={false} sx={sxWrapperBackground}>
              <AdditionalTrialSessionStepFive />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/step-six"
        element={
          <RequireLoggedIn>
            <Layout layout="default" footer={false} sx={sxWrapperBackground}>
              <AdditionalTrialSessionStepSix />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/booking/:classId"
        element={
          <RequireLoggedIn>
            <Layout layout="default" footer={false} sx={sxWrapperBackground}>
              <AdditionalTrialSessionBooking />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/additional-single-class/booking/:classId/success"
        element={
          <RequireLoggedIn>
            <Layout layout="centered">
              <AdditionalSingleSessionSuccess />
            </Layout>
          </RequireLoggedIn>
        }
      />
      <Route
        path="/direct-booking-subscription/tutor-introduction/:tutorId"
        element={
          <Layout layout="default" footer={false} sx={sxWrapperBackground}>
            <DirectBookingTutorIntroduction />
          </Layout>
        }
      />
      <Route
        path="/direct-booking-subscription/child-information"
        element={
          <Layout layout="default" footer={false} sx={sxWrapperBackground}>
            <DirectBookingChildInformation />
          </Layout>
        }
      />
      <Route
        path="/direct-booking-subscription/subject-selection"
        element={
          <Layout layout="default" footer={false} sx={sxWrapperBackground}>
            <DirectBookingSubjectSelection />
          </Layout>
        }
      />
      <Route
        path="/direct-booking-subscription/parent-signup"
        element={
          <Layout layout="default" footer={false} sx={sxWrapperBackground}>
            <DirectBookingParentSignup />
          </Layout>
        }
      />
      <Route
        path="/ai-transparency-note"
        element={
          <Layout layout="padded">
            <AITransparencyNote />
          </Layout>
        }
      />
      <Route path="/story" element={<Navigate to="/?utm_source=teacher_sharing&utm_campaign=storyfeed" replace />} />
      <Route path="/flier" element={<Navigate to="/?utm_source=teacher_sharing&utm_campaign=flier" replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
