import startCase from "lodash/startCase";

export type Skill = {
  label: string;
  value: string;
};
export type Skills = Skill[];
export interface SkillsMapping {
  [key: string]: Skills;
}

export const skillsData: SkillsMapping = {
  "pre-kindergarten_reading": [
    {
      label: "📚  Alphabet Basics",
      value: "alphabet_basics",
    },
    {
      label: "🎵  Rhyming Words",
      value: "rhyming_words",
    },
    {
      label: "🏠  Basic Vocabulary",
      value: "basic_vocabulary",
    },
    {
      label: "🐣  Phonemic Awareness",
      value: "phonemic_awareness",
    },
    {
      label: "📖  Story Retelling",
      value: "story_retelling",
    },
    {
      label: "🧩  Puzzle Words",
      value: "puzzle_words",
    },
  ],
  "pre-kindergarten_math": [
    {
      label: "🧮  Counting Basics",
      value: "counting_basics",
    },
    {
      label: "🔢  Simple Addition",
      value: "simple_addition",
    },
    {
      label: "🍎  Sorting Objects",
      value: "sorting_objects",
    },
    {
      label: "🔼  Shapes",
      value: "shapes",
    },
    {
      label: "📏  Size Comparison",
      value: "size_comparison",
    },
    {
      label: "🎓  Learning Numbers",
      value: "learning_numbers",
    },
  ],
  "pre-kindergarten_writing": [], // We don't allow writing for pre-kindergarten
  kindergarten_reading: [
    { label: "👂  Phonemic awareness", value: "phonemic_awareness" },
    { label: "👀  Sight words", value: "sight_words" },
    { label: "📖  Story comprehension", value: "story_comprehension" },
    { label: "🅰️  Letter recognition", value: "letter_recognition" },
    { label: "🌱  Vocabulary", value: "vocabulary" },
    { label: "📚  Reading in general", value: "reading_in_general" },
  ],
  kindergarten_math: [
    { label: "🧮  Counting", value: "counting" },
    { label: "🔢  Number recognition", value: "number_recognition" },
    { label: "🩶  Basic Shapes", value: "basic_shapes" },
    { label: "🟰  Addition and subtraction", value: "addition_and_subtraction" },
    { label: "🪵  Sorting and classifying", value: "sorting_and_classifying" },
    { label: "#️⃣  Math in general", value: "math_in_general" },
  ],
  kindergarten_writing: [
    { label: "✏️  Letter formation", value: "letter_formation" },
    { label: "🗒️  Forming sentences", value: "forming_sentences" },
    { label: "💬  Expressing ideas in writing", value: "expressing_ideas_in_writing" },
    { label: "📝  Writing in general", value: "writing_in_general" },
  ],
  "1st_grade_reading": [
    { label: "🧠  Decoding", value: "decoding" },
    { label: "⭐️  Fluency", value: "fluency" },
    { label: "💬  Reading comprehension", value: "reading_comprehension" },
    { label: "📣  Phonics", value: "phonics" },
    { label: "👀  Sight words", value: "sight_words" },
    { label: "📚  Reading in general", value: "reading_in_general" },
  ],
  "1st_grade_math": [
    { label: "🟰  Addition and subtraction", value: "addition_and_subtraction" },
    { label: "📍  Place value", value: "place_value" },
    { label: "📐  Measurement", value: "measurement" },
    { label: "🧮  Number patterns", value: "number_patterns" },
    { label: "🧱  Basic word problems", value: "basic_word_problems" },
    { label: "#️⃣  Math in general", value: "math_in_general" },
  ],
  "1st_grade_writing": [
    { label: "✏️  Sentence structure", value: "sentence_structure" },
    { label: "🎭  Creative writing", value: "creative_writing" },
    { label: "📓  Spelling", value: "spelling" },
    { label: "🎈  Punctuation", value: "punctuation" },
    { label: "🗺️  Descriptive writing", value: "descriptive_writing" },
    { label: "📝  Writing in general", value: "writing_in_general" },
  ],
  "2nd_grade_reading": [
    { label: "🌱  Vocabulary development", value: "vocabulary_development" },
    { label: "📙  Reading fluency", value: "reading_fluency" },
    { label: "👀  Sight words", value: "sight_words" },
    { label: "🧠  Text comprehension", value: "text_comprehension" },
    { label: "⭐️  Identifying main ideas", value: "identifying_main_ideas" },
    { label: "📚  Reading in general", value: "reading_in_general" },
  ],
  "2nd_grade_math": [
    { label: "➗  Multiplication and division", value: "multiplication_and_division" },
    { label: "💵  Money", value: "money" },
    { label: "⏰  Time", value: "time" },
    { label: "🧮  Addition and subtraction", value: "addition_and_subtraction" },
    { label: "🧱  Word problems", value: "word_problems" },
    { label: "#️⃣  Math in general", value: "math_in_general" },
  ],
  "2nd_grade_writing": [
    { label: "🎈  Punctuation", value: "punctuation" },
    { label: "📄  Paragraph structure", value: "paragraph_structure" },
    { label: "📓  Narrative writing", value: "narrative_writing" },
    { label: "🖌️  Creative expression", value: "creative_expression" },
    { label: "✂️  Editing", value: "editing" },
    { label: "📝  Writing in general", value: "writing_in_general" },
  ],
  "3rd_grade_reading": [
    { label: "⭐️  Main ideas", value: "main_ideas" },
    { label: "🤔  Critical thinking", value: "critical_thinking" },
    { label: "💬  Summarizing and paraphrasing", value: "summarizing_and_paraphrasing" },
    { label: "📚  Reading in general", value: "reading_in_general" },
  ],
  "3rd_grade_math": [
    { label: "🌓  Fractions", value: "fractions" },
    { label: "➗  Multiplication and division", value: "multiplication_and_division" },
    { label: "📐  Geometry", value: "geometry" },
    { label: "📏  Measurement", value: "measurement" },
    { label: "🧮  Addition and subtraction", value: "addition_and_subtraction" },
    { label: "#️⃣  Math in general", value: "math_in_general" },
  ],
  "3rd_grade_writing": [
    { label: "📃  Essay structure", value: "essay_structure" },
    { label: "✂️  Editing skills", value: "editing_skills" },
    { label: "🔎  Research skills", value: "research_skills" },
    { label: "🖌️  Creative writing", value: "creative_writing" },
    { label: "⚡️  Persuasive writing", value: "persuasive_writing" },
    { label: "📝  Writing in general", value: "writing_in_general" },
  ],
  "4th_grade_reading": [
    { label: "🧠  Critical thinking", value: "critical_thinking" },
    { label: "💬  Summarizing", value: "summarizing" },
    { label: "🔎  Context clues", value: "context_clues" },
    { label: "📚  Reading in general", value: "reading_in_general" },
  ],
  "4th_grade_math": [
    { label: "➗  Multiplication and division", value: "multiplication_and_division" },
    { label: "🌓  Fractions", value: "fractions" },
    { label: "🧩  Algebra", value: "algebra" },
    { label: "🔢  Multiplication of larger numbers", value: "multiplication_of_larger_numbers" },
    { label: "📐  Geometry concepts", value: "geometry_concepts" },
    { label: "#️⃣  Math in general", value: "math_in_general" },
  ],
  "4th_grade_writing": [
    { label: "⚡️  Persuasive writing", value: "persuasive_writing" },
    { label: "📋  Research reports", value: "research_reports" },
    { label: "✂️  Editing", value: "editing" },
    { label: "✍️  Narrative writing", value: "narrative_writing" },
    { label: "📓  Organizing ideas in writing", value: "organizing_ideas_in_writing" },
    { label: "📝  Writing in general", value: "writing_in_general" },
  ],
  "5th_grade_reading": [
    { label: "🤔  Critical thinking", value: "critical_thinking" },
    { label: "🤔  Evaluating arguments", value: "evaluating_arguments" },
    { label: "📚  Reading in general", value: "reading_in_general" },
  ],
  "5th_grade_math": [
    { label: "✂️  Decimals", value: "decimals" },
    { label: "🌓  Fractions", value: "fractions" },
    { label: "🧩  Algebraic thinking", value: "algebraic_thinking" },
    { label: "📐  Geometry", value: "geometry" },
    { label: "🧠  Problem solving", value: "problem_solving" },
    { label: "#️⃣  Math in general", value: "math_in_general" },
  ],
  "5th_grade_writing": [
    { label: "✍️  Expository writing", value: "expository_writing" },
    { label: "💬  Argumentative essays", value: "argumentative_essays" },
    { label: "🖍️  Revision", value: "revision" },
    { label: "📋  Research skills", value: "research_skills" },
    { label: "🎭  Creative expression", value: "creative_expression" },
    { label: "📝  Writing in general", value: "writing_in_general" },
  ],
  "6th_grade_reading": [
    { label: "🤔  Evaluating arguments", value: "evaluating_arguments" },
    { label: "💡  Inference", value: "inference" },
    { label: "📖  Genre analysis", value: "genre_analysis" },
    { label: "🏷️  Analyzing informational texts", value: "analyzing_informational_texts" },
    { label: "💬  Critical reading", value: "critical_reading" },
    { label: "📚  Reading in general", value: "reading_in_general" },
  ],
  "6th_grade_math": [
    { label: "🍱  Ratios and proportions", value: "ratios_and_proportions" },
    { label: "🌓  Fractions", value: "fractions" },
    { label: "🧩  Pre-algebra", value: "prealgebra" },
    { label: "📐  Geometry concepts", value: "geometry_concepts" },
    { label: "🧠  Problem solving", value: "problem_solving" },
    { label: "#️⃣  Math in general", value: "math_in_general" },
  ],
  "6th_grade_writing": [
    { label: "📋  Research papers", value: "research_papers" },
    { label: "🎭  Creative expression", value: "creative_expression" },
    { label: "✂️  Editing", value: "editing" },
    { label: "💬  Argumentative writing", value: "argumentative_writing" },
    { label: "🧠  Analyzing persuasive techniques", value: "analyzing_persuasive_techniques" },
    { label: "📝  Writing in general", value: "writing_in_general" },
  ],
  "7th_grade_reading": [
    {
      label: "📘  Comprehension Strategies",
      value: "comprehension_strategies",
    },
    {
      label: "📝  Text Analysis",
      value: "text_analysis",
    },
    {
      label: "🗣️  Point of View",
      value: "point_of_view",
    },
    {
      label: "🎭  Genre Study",
      value: "genre_study",
    },
    {
      label: "🔍  Critical Thinking",
      value: "critical_thinking",
    },
    {
      label: "🖊️  Summarizing Skills",
      value: "summarizing_skills",
    },
  ],
  "7th_grade_math": [
    {
      label: "➗  Fractions Basics",
      value: "fractions_basics",
    },
    {
      label: "📐  Geometry Intro",
      value: "geometry_intro",
    },
    {
      label: "📈  Data Analysis",
      value: "data_analysis",
    },
    {
      label: "✖️  Complex Multiplication",
      value: "complex_multiplication",
    },
    {
      label: "🧪  Ratio Relationships",
      value: "ratio_relationships",
    },
    {
      label: "📊  Algebra Expressions",
      value: "algebra_expressions",
    },
  ],
  "7th_grade_writing": [
    {
      label: "📜  Essay Techniques",
      value: "essay_techniques",
    },
    {
      label: "📅  Narrative Writing",
      value: "narrative_writing",
    },
    {
      label: "📊  Research Skills",
      value: "research_skills",
    },
    {
      label: "🗣️  Persuasive Writing",
      value: "persuasive_writing",
    },
    {
      label: "🔖  Revision Techniques",
      value: "revision_techniques",
    },
    {
      label: "🎨  Creative Writing",
      value: "creative_writing",
    },
  ],
  "8th_grade_reading": [
    {
      label: "🏰  Cultural Texts",
      value: "cultural_texts",
    },
    {
      label: "🖼️  Theme Analysis",
      value: "theme_analysis",
    },
    {
      label: "📖  Advanced Comprehension",
      value: "advanced_comprehension",
    },
    {
      label: "🗣️  Public Speaking",
      value: "public_speaking",
    },
    {
      label: "🤔  Philosophical Reading",
      value: "philosophical_reading",
    },
    {
      label: "📑  Scientific Texts",
      value: "scientific_texts",
    },
  ],
  "8th_grade_math": [
    {
      label: "📏  Advanced Geometry",
      value: "advanced_geometry",
    },
    {
      label: "💠  Algebra Equations",
      value: "algebra_equations",
    },
    {
      label: "📉  Function Analysis",
      value: "function_analysis",
    },
    {
      label: "⭐  Pythagorean Applications",
      value: "pythagorean_applications",
    },
    {
      label: "🎲  Probability Intro",
      value: "probability_intro",
    },
    {
      label: "📚  Problem Solving",
      value: "problem_solving",
    },
  ],
  "8th_grade_writing": [
    {
      label: "📑  Expository Writing",
      value: "expository_writing",
    },
    {
      label: "📖  Analytical Essays",
      value: "analytical_essays",
    },
    {
      label: "🗣️  Debate Skills",
      value: "debate_skills",
    },
    {
      label: "🔬  Research Papers",
      value: "research_papers",
    },
    {
      label: "🖊️  Advanced Grammar",
      value: "advanced_grammar",
    },
    {
      label: "🎓  High School Prep",
      value: "high_school_prep",
    },
  ],
  "9th_grade_reading": [
    {
      label: "🌍  Global Literature",
      value: "global_literature",
    },
    {
      label: "📚  Complex Texts",
      value: "complex_texts",
    },
    {
      label: "🖊️  Critical Analysis",
      value: "critical_analysis",
    },
    {
      label: "📖  Literature Comparison",
      value: "literature_comparison",
    },
    {
      label: "📝  Literature Response",
      value: "literature_response",
    },
    {
      label: "📝  Test Prep",
      value: "test_prep",
    },
  ],
  "9th_grade_math": [
    {
      label: "📊  Algebra Advanced",
      value: "algebra_advanced",
    },
    {
      label: "🔍  Geometry Proofs",
      value: "geometry_proofs",
    },
    {
      label: "🌐  Trigonometry Basics",
      value: "trigonometry_basics",
    },
    {
      label: "📊  Statistics Intro",
      value: "statistics_intro",
    },
    {
      label: "🔄  Calculus Introduction",
      value: "calculus_introduction",
    },
    {
      label: "📝  Test Prep",
      value: "test_prep",
    },
  ],
  "9th_grade_writing": [
    {
      label: "🎓  Essay Structuring",
      value: "essay_structuring",
    },
    {
      label: "📖  Descriptive Writing",
      value: "descriptive_writing",
    },
    {
      label: "🗣️  Persuasive Techniques",
      value: "persuasive_techniques",
    },
    {
      label: "🔬  Technical Writing",
      value: "technical_writing",
    },
    {
      label: "🎨  Creative Expression",
      value: "creative_expression",
    },
    {
      label: "📝  Test Prep",
      value: "test_prep",
    },
  ],
};

export type TutorGrade =
  | "pre-kindergarten"
  | "kindergarten"
  | "1st_grade"
  | "2nd_grade"
  | "3rd_grade"
  | "4th_grade"
  | "5th_grade"
  | "6th_grade"
  | "7th_grade"
  | "8th_grade"
  | "9th_grade"
  | "10th_grade"
  | "11th_grade"
  | "12th_grade";

export type TutorSubject = "math" | "reading" | "writing" | "summer_prep_course" | "winter_boost";

export const newOfferedGradesAndLabels: { grade: TutorGrade; label: string }[] = [
  // initial list of grades for a parent to choose from
  { grade: "pre-kindergarten", label: "Pre-K" },
  { grade: "kindergarten", label: "Kindergarten" },
  { grade: "1st_grade", label: "1st" },
  { grade: "2nd_grade", label: "2nd" },
  { grade: "3rd_grade", label: "3rd" },
  { grade: "4th_grade", label: "4th" },
  { grade: "5th_grade", label: "5th" },
  { grade: "6th_grade", label: "6th" },
  { grade: "7th_grade", label: "7th" },
  { grade: "8th_grade", label: "8th" },
  { grade: "9th_grade", label: "9th" },
  // { grade: "10th_grade", label: "10th" },
  // { grade: "11th_grade", label: "11th" },
  // { grade: "12th_grade", label: "12th" },
];

export const labelByTutorGrade: { [_ in TutorGrade]: string } = {
  "pre-kindergarten": "Pre Kindergarten",
  kindergarten: "Kindergarten",
  "1st_grade": "1st",
  "2nd_grade": "2nd",
  "3rd_grade": "3rd",
  "4th_grade": "4th",
  "5th_grade": "5th",
  "6th_grade": "6th",
  "7th_grade": "7th",
  "8th_grade": "8th",
  "9th_grade": "9th",
  "10th_grade": "10th",
  "11th_grade": "11th",
  "12th_grade": "12th",
};

type GradeLevelType =
  | "pre-kindergarten"
  | "kindergarten"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

const labelExtendedByTutorGrade: { [_ in GradeLevelType]: string } = {
  "pre-kindergarten": "Pre-K",
  kindergarten: "Kindergarten",
  "1": "1st grade",
  "2": "2nd grade",
  "3": "3rd grade",
  "4": "4th grade",
  "5": "5th grade",
  "6": "6th grade",
  "7": "7th grade",
  "8": "8th grade",
  "9": "9th grade",
  "10": "10th grade",
  "11": "11th grade",
  "12": "12th grade",
};

export const labelAndEmojiBySubject: { [_ in TutorSubject]: { label: string; emoji: string } } = {
  reading: { label: "Reading", emoji: "📚" },
  writing: { label: "Writing", emoji: "✏️" },
  math: { label: "Math", emoji: "➗" },
  summer_prep_course: { label: "Math & reading", emoji: "☀️" },
  winter_boost: { label: "Winter Boost", emoji: "📚" },
};

export const getSubjectLabel = (subject?: string) => {
  if (!subject || !labelAndEmojiBySubject[subject as TutorSubject]) {
    return "";
  }

  return labelAndEmojiBySubject[subject as TutorSubject].label;
};

export const getGradeLabel = (gradeLevel: GradeLevelType) => {
  if (!labelExtendedByTutorGrade[gradeLevel]) {
    return "";
  }

  return labelExtendedByTutorGrade[gradeLevel];
};

export const getSubjectLabelForBlueshift = (subject: string) => {
  const labels: Record<TutorSubject, string> = {
    reading: "reading",
    writing: "writing",
    math: "math",
    summer_prep_course: "Math & Reading",
    winter_boost: "Winter Reading Boost",
  };
  return labels[subject as TutorSubject] ?? startCase(subject);
};
