import { theme, LabelText, MinutiaeText, BodyText } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useQueryParams from "src/hooks/useQueryParams";
import { z } from "zod";
import { useClassByIdQuery, useParentMutation } from "old/src/hooks/useParent";
import { useScheduleSlotsQuery } from "old/src/hooks/useTeacher";
import { BookingType, useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import { showErrorNotification } from "old/src/utils";
import type { Grade, Subject } from "src/api/parent";
import RadioGroup from "src/components/RadioGroup";
import RadioGroupOption from "src/components/RadioGroup/RadioGroupOption";
import Field from "src/components/form/Field";
import SingleClassForm from "src/pageComponents/NewSingleClass/SingleClassForm";
import {
  sxClickableOption,
  sxHorizontalRadioGroup,
  sxMoreButton,
} from "src/pageComponents/NewSingleClass/SharedStyles";
import useWatch from "@classdojo/web/hooks/useWatch";
import { getDefaultTimezone, getLocalizedDateTime } from "src/utils/dates";
import TimezoneSelect from "src/components/TimezoneSelect";
import { LoadingMojo } from "@classdojo/web";
import useOnFirstRender from "@classdojo/web/hooks/useOnFirstRender";
import { logTutorEvent } from "src/utils/log";
import useParentBackgroundMutation, { getBackground } from "src/hooks/useParentBackgroundMutation";
import {
  startOf,
  addDays,
  formatWithPattern,
  getDayOfMonth,
  parse,
  parseWithPattern,
  getDayOfWeek,
} from "src/utils/dates";
import { useSessionQuery } from "src/hooks/useSession";

const FormSchema = z.object({
  date: z.string(),
  time: z.string(),
  timezone: z.string(),
});
type FormData = Partial<z.infer<typeof FormSchema>> & { timezone: string };
const PENDING_CLASS_STATUSES = ["created", "pending"];

const AdditionalSingleSessionStepSix = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();
  const { bookClass, updateClass } = useParentMutation();
  const [bookingLoading, setBookingLoading] = useState(false);
  const { sessionData } = useSessionQuery();
  const userTimezone = sessionData?.user_details?.timezone;
  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: { date: "", time: "", timezone: userTimezone ?? getDefaultTimezone() },
  });
  const watchTimezone = form.watch("timezone");
  const [showAllSlots, setShowAllSlots] = useState(false);
  const bookingClassId = state.booking?.uid;
  const { classData } = useClassByIdQuery(bookingClassId);
  const parentBackgroundMutation = useParentBackgroundMutation();

  const handleShowAllSlots = () => {
    setShowAllSlots(!showAllSlots);
  };

  useOnFirstRender(() => {
    if (state.booking?.grade && state.booking?.first_name_child && state.booking?.subject) {
      logTutorEvent({
        eventName: "parent.marketplace.logged_in_trial_booking.step_3",
        metadata: {
          subject: state.booking.subject,
        },
        experiments: ["web_marketplace_post_trial_book_another_trial_dec2024"],
      });
      logTutorEvent("parent.marketplace.retrial_date_time_picker_viewed");
    } else {
      push("/additional-single-class/step-one");
    }
  });

  const queryParams = useQueryParams();

  const onDateChange = useCallback(
    (value: string) => {
      form.setValue("time", undefined, { shouldValidate: true });
      form.setValue("date", value, { shouldValidate: true });
      form.setError("root", { type: "manual", message: "" });
      setSelectedDate(value);
    },
    [form],
  );

  const hasHandledErrorRef = useRef(false);
  React.useEffect(() => {
    if (!hasHandledErrorRef.current && queryParams.error) {
      onDateChange("");
      form.setError("root", { type: "manual", message: "Please select a new date and time." });
      hasHandledErrorRef.current = true;
    } else if (state.booking) {
      form.setValue("date", state.booking.date, { shouldValidate: true });
      form.setValue("time", state.booking.time, { shouldValidate: true });
      if (state.booking.timezone) {
        form.setValue("timezone", state.booking.timezone, { shouldValidate: true });
      }
    }
  }, [form, onDateChange, queryParams.error, state.booking]);

  const [selectedDate, setSelectedDate] = useState<string>("");

  useWatch(state.booking, () => {
    if (state.booking && state.booking.date) {
      setSelectedDate(state.booking.date);
    }
  });

  const { availableSlots, isLoading: availableSlotsLoading } = useScheduleSlotsQuery(
    state.booking && {
      subject: state.booking.subject,
      grade: state.booking.grade,
    },
  );

  const availableDays = useMemo(() => {
    const timezone = watchTimezone;
    if (!availableSlots || !timezone) return [];

    const startingDay = addDays(startOf(new Date(), "day", timezone), 1);
    const availableSlotsTimes = availableSlots ? availableSlots.map((slot) => parse(slot)) : [];

    return Array.from({ length: 7 }, (_v, index) => {
      const day = addDays(startingDay, index);
      const nextDay = addDays(startingDay, index + 1);

      const slots = availableSlotsTimes
        .filter((slot) => slot >= day && slot < nextDay)
        .map((slot) => formatWithPattern(parse(slot), "hh:mm a", { timeZone: timezone }));

      return { day: formatWithPattern(day, "PP", { timeZone: timezone }), slots };
    });
  }, [availableSlots, watchTimezone]);

  const selectedDaySlots = useMemo(() => {
    const dayData = availableDays.find((day) => day.day === selectedDate);
    return dayData ? dayData.slots : [];
  }, [availableDays, selectedDate]);

  const filteredSlots = useMemo(() => {
    const weekday = getDayOfWeek(parseWithPattern(selectedDate, "PP"), watchTimezone);

    let centralRange = ["03:00 PM", "07:00 PM"];
    if (weekday === 0 || weekday === 6) {
      centralRange = ["11:00 AM", "02:00 PM"];
    }
    let filteredSlots = selectedDaySlots.filter(
      (slot) =>
        parseWithPattern(slot, "hh:mm a") >= parseWithPattern(centralRange[0], "hh:mm a") &&
        parseWithPattern(slot, "hh:mm a") <= parseWithPattern(centralRange[1], "hh:mm a"),
    );

    if (filteredSlots.length < 5) {
      // special case, we don't have 9 values
      if (selectedDaySlots.length < 10) {
        setShowAllSlots(true);
        return selectedDaySlots;
      }
      const midIndex = Math.floor(selectedDaySlots.length / 2);

      const startIndex = midIndex - 4;

      filteredSlots = selectedDaySlots.slice(startIndex, midIndex + 5);
    }

    setShowAllSlots(filteredSlots.length === selectedDaySlots.length);
    return filteredSlots;
  }, [selectedDaySlots, selectedDate, watchTimezone]);

  const onNextStep: SubmitHandler<FormData> = async (values) => {
    if (bookingLoading) return;
    const booking = { ...state.booking, ...values } as BookingType;
    dispatch({ type: actionTypes.UPDATE_BOOKING, payload: booking });
    logTutorEvent("parent.marketplace.retrial_date_time_picker_completed");

    if (
      bookingClassId &&
      booking.grade &&
      booking.subject &&
      classData?.status &&
      PENDING_CLASS_STATUSES.includes(classData.status)
    ) {
      await onUpdateBooking({
        grade: booking.grade,
        subject: booking.subject,
        first_name_child: booking.first_name_child ?? "",
        classId: bookingClassId,
      });
    } else if (booking.grade && booking.subject) {
      await onTriggerBooking(booking);
    }
  };

  const onUpdateBooking = async (bookingData: {
    classId: string;
    grade: Grade;
    subject: Subject;
    first_name_child: string;
  }) => {
    if (state.booking == null || bookingLoading) return;

    setBookingLoading(true);

    const ts = getLocalizedDateTime(
      form.getValues("date"),
      form.getValues("time"),
      form.getValues("timezone"),
    ).toISOString();

    updateClass.mutate(
      {
        id: bookingData.classId,
        startTime: ts,
        grade: bookingData.grade,
        subject: bookingData.subject,
        childName: bookingData.first_name_child,
      },
      {
        onSuccess: async () => {
          if (state.booking != null) {
            push(`/additional-single-class/booking/${state.booking.uid}`);
          }
        },
        onError: (error: AxiosError<{ error: string }>) => {
          showErrorNotification();
          form.setError("time", { type: "manual", message: error?.response?.data?.error });
          setBookingLoading(false);
        },
      },
    );

    setBookingLoading(false);
  };

  const onTriggerBooking = async (booking: BookingType) => {
    if (state.booking == null || bookingLoading) return;

    setBookingLoading(true);

    const ts = getLocalizedDateTime(
      form.getValues("date"),
      form.getValues("time"),
      form.getValues("timezone"),
    ).toISOString();

    bookClass.mutate(
      {
        childName: booking.first_name_child,
        grade: booking.grade,
        subject: booking.subject,
        startTime: ts,
      },
      {
        onSuccess: async (data) => {
          const { classId, tutorId, startTime } = data.data;
          const date = formatWithPattern(parse(startTime), "MMMM d yyyy");
          const time = formatWithPattern(parse(startTime), "hh:mm a");

          if (booking.first_name_child) {
            const background = getBackground(booking);
            await parentBackgroundMutation.mutateAsync(
              {
                classId,
                background,
                childName: booking.first_name_child,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- resolve by adding onError to options here
              },
              {} as any,
            );
          }

          dispatch({
            type: actionTypes.UPDATE_BOOKING,
            payload: { ...state?.booking, uid: classId, teacher_uid: tutorId, date, time },
          });
          push(`/additional-single-class/booking/${classId}`);
        },
        onError: (error: AxiosError<{ error: string }>) => {
          showErrorNotification();
          form.setError("time", { type: "manual", message: error?.response?.data?.error });
          setBookingLoading(false);
        },
      },
    );

    setBookingLoading(false);
  };

  const today = getDayOfWeek(startOf(new Date(), "day"));
  const additionalTimesCount = () => {
    return selectedDaySlots.length - filteredSlots.length;
  };

  const pageTitle = `Pick a date for ${state.booking?.first_name_child}’s trial class`;

  return (
    <SingleClassForm
      disabled={!form.formState.isValid || form.formState.isSubmitting || bookingLoading}
      title={pageTitle}
      onSubmit={form.handleSubmit(onNextStep)}
      onBack={() =>
        push(state.booking?.isNewChild ? "/additional-single-class/step-five" : "/additional-single-class/step-two")
      }
      contentMaxWidth={450}
      error={form.formState.errors.root ? form.formState.errors.root.message.toString() : undefined}
      hideZendeskBubbleOnCleanUp
    >
      <div sx={sxFieldsWrapper}>
        <div sx={{ display: "flex", gap: "dt_s", alignItems: "center" }}>
          <span>Timezone:</span>
          <Field
            form={form}
            name="timezone"
            render={({ value: timezone, onChange: onTimezoneChange }) => (
              <TimezoneSelect selectedValue={timezone} onChange={onTimezoneChange} disabled={!!userTimezone} />
            )}
          />
        </div>
        {availableSlotsLoading ? (
          <LoadingMojo />
        ) : (
          <Field
            form={form}
            name="date"
            render={({ value: date }) => (
              <RadioGroup sx={sxGrid} value={date} onChange={onDateChange}>
                <LabelText level={2}>Mo</LabelText>
                <LabelText level={2}>Tu</LabelText>
                <LabelText level={2}>We</LabelText>
                <LabelText level={2}>Th</LabelText>
                <LabelText level={2}>Fr</LabelText>
                <LabelText level={2}>Sa</LabelText>
                <LabelText level={2}>Su</LabelText>
                <div sx={{ gridColumnStart: today === 0 ? 7 : today }}>
                  <MinutiaeText sx={{ color: "dt_content_secondary" }}>Today</MinutiaeText>
                  <BodyText level={1} sx={{ color: "dt_content_tertiary" }}>
                    {getDayOfMonth(new Date())}
                  </BodyText>
                </div>
                {availableDays.map(({ day, slots }, index) => (
                  <RadioGroupOption
                    key={index}
                    value={day}
                    disabled={slots.length < 1}
                    sxOption={{ ...sxClickableOption, paddingInline: "dt_xs" }}
                    name={`day_${formatWithPattern(parseWithPattern(day, "PP"), "dd")}`}
                  >
                    {/*TODO(dates): double check that these shouldn't include TZ*/}
                    {formatWithPattern(parseWithPattern(day, "PP"), "d")}
                  </RadioGroupOption>
                ))}
              </RadioGroup>
            )}
          />
        )}

        {selectedDate && selectedDaySlots.length > 0 && (
          <>
            <div sx={{ borderTop: "dt_card", paddingTop: "dt_l" }}>
              <LabelText level={1}>{selectedDate}</LabelText>
              <BodyText level={2}>
                Choose a time at which you and {state.booking?.first_name_child} can meet the tutor for 25 minutes on
                Zoom.
              </BodyText>
            </div>
            <Field
              form={form}
              name="time"
              render={({ value: time, onChange: onTimeChange }) => (
                <RadioGroup
                  value={time}
                  onChange={(newValue) => {
                    onTimeChange(newValue);
                  }}
                  sx={{ ...sxHorizontalRadioGroup, justifyContent: "flex-start", gap: "dt_xs" }}
                >
                  {(showAllSlots ? selectedDaySlots : filteredSlots).map((slot, index) => (
                    <RadioGroupOption
                      key={index}
                      value={slot}
                      sxOption={{ ...sxClickableOption, paddingInline: "dt_s" }}
                      name={slot}
                    >
                      {slot}
                    </RadioGroupOption>
                  ))}
                  {!showAllSlots && (
                    <button type="button" sx={sxMoreButton} onClick={handleShowAllSlots}>
                      {additionalTimesCount()} more times
                    </button>
                  )}
                </RadioGroup>
              )}
            />
          </>
        )}
      </div>
    </SingleClassForm>
  );
};

export default AdditionalSingleSessionStepSix;

const sxGrid: ThemeUIStyleObject = {
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  gridTemplateRows: "repeat(2, auto)",
  gap: "dt_xs",
  textAlign: "center",
};
const sxFieldsWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  marginTop: "dt_m",
  marginBottom: "dt_xl",
  width: "100%",
  gap: "dt_xl",
  maxWidth: "456px",
  [`@media (max-width: ${theme.breakpoints.s})`]: {
    gap: "dt_l",
  },
};
