import { Elements } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import { useClassByIdQuery, useStripeSecret } from "old/src/hooks/useParent";
import { GET_STRIPE } from "src/constants/client";
import CCPayment from "src/pageComponents/AdditionalSingleSession/Booking/CCPayment";
import FreeTrial from "src/pageComponents/AdditionalSingleSession/Booking/FreeTrial";
import { logTutorEvent } from "src/utils/log";
import React from "react";
import { useGlobalStore } from "old/src/store";
import useSessionIsApplicableForFreeTrial from "src/hooks/useSessionIsApplicableForFreeTrial";
import { isBefore, addHours, parse, formatISO } from "src/utils/dates";

export default () => {
  const push = useNavigate();
  const { classId } = useParams();
  const { classData, isInitialLoading: isClassDataLoading } = useClassByIdQuery(classId);
  const { clientSecret, isFetching, isSuccess } = useStripeSecret(classId);
  const [state] = useGlobalStore();
  const sessionToBeReplacedId = state.booking?.replacementForSession;
  const { data: isApplicableForFreeTrialData, isFetching: isApplicableForFreeTrialLoading } =
    useSessionIsApplicableForFreeTrial(sessionToBeReplacedId);

  React.useEffect(() => {
    if (classData) {
      const sessionTime = parse(classData.startTime);
      if (classData.status === "paid") {
        push("/parents/classes");
      } else if (isBefore(sessionTime, addHours(new Date(), 24))) {
        push("/additional-single-class/step-six");
      } else {
        logTutorEvent({
          eventName: "parent.marketplace.logged_in_trial_booking.step_4",
          metadata: {
            subject: classData.subject,
            datetime: sessionTime.toISOString(),
            date: formatISO(sessionTime, { representation: "date" }),
            time: formatISO(new Date(), { representation: "time", format: "extended" }).substring(0, 5),
          },
          experiments: ["web_marketplace_post_trial_book_another_trial_dec2024"],
        });
      }
    }
  }, [classData, push]);

  const isFreeTrialCheckingLoading =
    isApplicableForFreeTrialLoading || (!!sessionToBeReplacedId && !isApplicableForFreeTrialData?.data);

  if (!clientSecret || isFetching || isFreeTrialCheckingLoading || !classData || isClassDataLoading || !isSuccess) {
    return null;
  }

  if (sessionToBeReplacedId && isApplicableForFreeTrialData?.data.isApplicable) {
    return <FreeTrial classData={classData} sessionToBeReplacedId={sessionToBeReplacedId} />;
  }

  return (
    <Elements stripe={GET_STRIPE()} options={{ clientSecret: clientSecret?.client_secret }}>
      <CCPayment clientSecret={clientSecret?.client_secret} classData={classData} />
    </Elements>
  );
};
